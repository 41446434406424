exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-krankheit-verstehen-tsx": () => import("./../../../src/pages/krankheit-verstehen.tsx" /* webpackChunkName: "component---src-pages-krankheit-verstehen-tsx" */),
  "component---src-pages-staerke-dich-tsx": () => import("./../../../src/pages/staerke-dich.tsx" /* webpackChunkName: "component---src-pages-staerke-dich-tsx" */),
  "component---src-pages-ueber-das-projekt-tsx": () => import("./../../../src/pages/ueber-das-projekt.tsx" /* webpackChunkName: "component---src-pages-ueber-das-projekt-tsx" */),
  "component---src-pages-ueber-dich-tsx": () => import("./../../../src/pages/ueber-dich.tsx" /* webpackChunkName: "component---src-pages-ueber-dich-tsx" */),
  "component---src-pages-zukunft-tsx": () => import("./../../../src/pages/zukunft.tsx" /* webpackChunkName: "component---src-pages-zukunft-tsx" */)
}

